<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12 mb-3">
        <div class="row align-items-center">
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-4">
                <b-input id="inline-form-input-name" @keyup.enter="search()" v-model="nick" placeholder="Nick"></b-input>
              </div>
              <div class="col-4">
                 <b-form-select v-model="position" @change="search()" :options="positions"></b-form-select>
              </div>
              <div class="col-4">
                <b-button variant="primary" @click="search()" block>{{ $t('search') }}</b-button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex flex-column justify-content-center align-items-center" v-if="tournamentIsPremium">
              <span>{{ $t('availableMoney') }}</span>
              <strong>4484848</strong>
            </div>
          </div>
          <div class="col-12 col-md-4" v-if="isAuthenticated">
            <div class="d-flex justify-content-end align-items-center" v-if="!inside">
              <b-button v-if="!gameHere" class="mx-1" variant="primary" @click="openModalConfirm">{{ $t('joinTheMarket') }}</b-button>
              <b-button v-if="iAmDt" variant="primary" class="mx-1" :to="{ name: 'TournamentSellPlayers', params: { lang: lang, console: console, tournamentSlug: tournamentFront.tournament.slug, teamSlug: iAmDt.slug } }">{{ $t('sellPlayers') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-bordered">
            <thead class="theah-inverted">
              <th>{{ $t('player') }}</th>
              <th></th>
              <th>
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('status') }}</span>
                  <div class="arrows-sort">
                    <b-button @click="onSortByStatus('desc')" :class="{ active: sortByStatus === 'desc' }">
                      <i class="fa fa-arrow-down"></i>
                    </b-button>
                    <b-button @click="onSortByStatus('asc')" :class="{ active: sortByStatus === 'asc' }">
                      <i class="fa fa-arrow-up"></i>
                    </b-button>
                  </div>
                </div>
              </th>
              <th v-if="tournamentIsPremium">
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('price') }}</span>
                  <div class="arrows-sort">
                    <b-button @click="onSortByPrice('desc')" :class="{ active: sortByPrice === 'desc' }">
                      <i class="fa fa-arrow-down"></i>
                    </b-button>
                    <b-button @click="onSortByPrice('asc')" :class="{ active: sortByPrice === 'asc' }">
                      <i class="fa fa-arrow-up"></i>
                    </b-button>
                  </div>
                </div>
                </th>
              <th>{{ $t('purchasedBy') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(player, ind) in players" :key="ind">
                <td>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
                    <img :src="player.avatar" :alt="player.nick" class="img-icon mr-2">
                    {{ player.nick }}
                  </router-link>
                </td>
                <td class="text-center">
                  <b-button variant="outline-primary" class="btn-icon" @click="showUserPerfil(player.user_id)">
                    <i class="fa fa-eye"></i>
                  </b-button>
                </td>
                <td>{{ player.status === 'P' ? $t('free') : $t('signing') }}</td>
                <td v-if="tournamentIsPremium" class="text-right">{{ player.price | moneyFormat }}</td>
                <td>
                  <router-link v-if="player.team_name" :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: player.team_slug } }">
                    <img :src="player.image" :alt="player.name" class="img-icon mr-2">
                    {{ player.team_name }}
                  </router-link>
                </td>
                <td class="text-center">
                  <b-button v-if="iAmDt && isAuthenticated && player.status ==='P'" variant="success" class="mx-1" @click="contractPlayer(player)">{{ $t('contract') }}</b-button>
                  <b-button v-if="isAuthenticated && player.user_id !== user.id" class="mx-1" variant="info" @click="openModalMessage(player)">
                    <i class="fa fa-envelope"></i>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="paginate"></b-pagination>
      </div>
    </div>
    <b-modal v-if="player.user" ref="modalUserStats" :title="player.user.nick" :hide-footer="true">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="player-card mb-3">
              <img :src="player.user.card" :alt="`Tarjeta ${player.user.nick}`" class="img-card">
              <div class="player-card-left">
                <span class="overall border-text-black">{{ player.user.overall }}</span>
                <span class="position border-text-black">{{ player.user.position }}</span>
                <img v-if="player.user.team" :src="player.user.team.image" :alt="player.user.team.name" :title="player.user.team.name" class="img-team">
                <img :src="player.user.country_flag" :alt="`Pais de ${player.user.nick}`" class="img-country">
              </div>
              <div class="player-card-right">
                <img :src="player.user.avatar" :alt="`Avatar de ${player.user.nick}`" class="img-avatar">
              </div>
              <h2 class="player-card-user border-text-black">{{ player.user.nick }}</h2>
              <div class="player-card-chemstyle">
                <img :src="player.user.chem_style_image" :alt="`Chemstyle de ${player.user.nick}`">
              </div>
            </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-8 text-right">
              <h6>{{ $t('position') }}</h6>
              <h6>{{ $t('playedGames') }}</h6>
              <h6>{{ $t('bestPlayer') }}</h6>
              <h6>{{ $t('assistances') }}</h6>
              <h6>{{ $tc('goal', 5) | toCapitalize }}</h6>
              <h6>{{ $tc('redCard', 2) }}</h6>
              <h6>{{ $tc('yellowCard', 2) }}</h6>
            </div>
            <div class="col-4">
              <h6>{{ player.user.position }}</h6>
              <h6>{{ user.games_playeds }}</h6>
              <h6>{{ user.bests }}</h6>
              <h6>{{ user.assits }}</h6>
              <h6>{{ user.goals }}</h6>
              <h6>{{ user.red_cards }}</h6>
              <h6>{{ user.yellow_cards }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
              <chart :chart-data="chart" :options="{ responsive: true }"></chart>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modalConfirmJoinTheMarket" :title="$t('notice')">
      <h6>{{ $t('joinTheMarketConfirm') }}</h6>
      <div slot="modal-footer" class="text-center w-100">
        <b-button variant="outline-primary" class="mx-2" @click="$refs.modalConfirmJoinTheMarket.hide()">{{ $t('not') }}</b-button>
        <b-button variant="primary" class="mx-2" @click="signMe">{{ $t('yes') }}</b-button>
      </div>
    </b-modal>
    <b-modal v-if="iAmDt" ref="modalContract" :title="$t('contract')" size="sm">
      <h6 class="text-center">{{ selectedPlayer.nick }}</h6>
      <hr>
      <div class="form-group row align-items-center justify-content-between">
        <label class="col-3 pr-3 text-right mb-0" for="team">{{ $t('team') }}</label>
        <input type="text" name="team" id="team" class="form-control col-9" v-model="iAmDt.name" readonly>
      </div>
      <div class="form-group row align-items-center justify-content-between">
        <label class="col-3 pr-3 text-right mb-0" for="team">{{ $tc('round', 2) | toCapitalize }}</label>
        <select name="rounds" id="rounds" required class="form-control col-9" v-model="rounds">
          <option v-for="n in 16" :value="n" :key="n">{{ n }}</option>
        </select>
      </div>
      <div v-if="tournamentIsPremium" class="form-group row align-items-center justify-content-between">
        <label class="col-3 pr-3 text-right mb-0" for="price">{{ $t('price') }}</label>
        <input type="text" name="price" id="price" class="form-control col-9" v-model="selectedPlayer.price" readonly>
      </div>
      <div slot="modal-footer" class="w-100">
        <div class="w-100 text-center">
          <b-button class="mx-1" variant="outline-primary" @click="$refs.modalContract.hide()">{{ $t('cancel') }}</b-button>
          <b-button ref="btnSendContract" class="mx-1" variant="primary" @click="sendContract">{{ $t('contract') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-if="selectedPlayer" ref="modalSendMessage" :title="$t('privateMsgFor', { user: selectedPlayer.nick })">
      <div class="form-group">
        <textarea name="message" id="message" class="form-control" :placeholder="$t('message')" maxlength="190" rows="5" v-model="selectedPlayer.message"></textarea>
      </div>
      <div slot="modal-footer" class="w-100">
        <div class="w-100 text-center">
          <b-button class="mx-1" variant="outline-primary" @click="$refs.modalSendMessage.hide()">{{ $t('cancel') }}</b-button>
          <b-button ref="btnSendMessage" class="mx-1" variant="primary" @click="sendMessage">{{ $t('submit') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Chart from '../partials/Chart.js'

export default {
  components: {
    Chart
  },
  data () {
    return {
      rounds: 1,
      players: [],
      currentPage: 1,
      rows: 0,
      perPage: 0,
      path: '',
      positions: [],
      position: '',
      sortByStatus: '',
      sortByPrice: '',
      nick: '',
      player: {
        user: {}
      },
      chart: null,
      inside: false,
      gameHere: false,
      tournamentIsPremium: false,
      iAmDt: false,
      selectedPlayer: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournamentFront',
      'isAuthenticated',
      'user'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'signings')
    this.positions = window.positions
    this.positions.unshift({
      value: '',
      text: this.$t('selectOption')
    })
    const queries = this.$route.query
    this.nick = queries.nick ? queries.nick : ''
    this.position = queries.position ? queries.position : ''
    this.sortByStatus = queries.sortbystatus ? queries.sortbystatus : ''
    this.sortByPrice = queries.sortbyprice ? queries.sortbyprice : ''
    this.fetchData()
  },
  methods: {
    openModalMessage(player) {
      this.selectedPlayer = player
      this.$refs.modalSendMessage.show()
    },
    sendMessage() {
      this.$refs.btnSendMessage.blur()
      const params = {
        sender: this.user.id,
        receiver: this.selectedPlayer.user_id,
        message: this.selectedPlayer.message
      }
      const path = `auth/${this.lang}/console/${this.console}/send-message`
      this.$axios.post(path, params).then(response => {
        this.selectedPlayer = {}
        this.$refs.modalSendMessage.hide()
        this.$toastr.success(response.data.message)
      })
    },
    sendContract() {
      this.$refs.btnSendContract.blur()
      const params = {
        user_id: this.selectedPlayer.user_id,
        rounds: this.rounds,
        type: 'N',
        team_id: this.iAmDt.team_id,
        team_name: this.iAmDt.name,
        position: this.iAmDt.position,
        signing_id: this.selectedPlayer.signing_id
      }
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/console/${this.console}/tournament/${tournamentId}/signings/send-invitation`
      this.$axios.post(path, params).then(response => {
        this.$refs.modalContract.hide()
        this.$toastr.success(response.data.message);
      })
    },
    contractPlayer(player) {
      this.selectedPlayer = player
      this.$refs.modalContract.show()
    },
    closeModalConfirmJoinTheMarket() {
      this.$refs.modalConfirmJoinTheMarket.hide()
    },
    signMe() {
      const tournamentId = this.tournamentFront.tournament.id
      const path = `auth/${this.lang}/console/${this.console}/tournament/${tournamentId}/signings/signin`
      this.$axios.post(path).then(response => {
        this.$toastr.success(response.data.message)
        this.$refs.modalConfirmJoinTheMarket.hide()
        this.fetchData()
      })
    },
    openModalConfirm() {
      this.$refs.modalConfirmJoinTheMarket.show();
    },
    showUserPerfil(userId) {
      const path = `${this.lang}/console/${this.console}/tournament/${this.$route.params.tournamentSlug}/player/${userId}/stats-for-signings`
      this.$axios.get(path).then(response => {
        const data = response.data
        this.player = data
        const colors = data.position_playeds.map(() => {
          return this.generateRandomColor()
        })
        const datasets = {
          labels: data.position_playeds.map(pos => {
            return pos.position
          }),
          datasets: [
            {
              data: data.position_playeds.map(pos => {
                return pos.total
              }),
              backgroundColor: colors,
              borderColor: colors,
              borderWidth: 2
            }
          ]
        }
        this.chart = datasets
        this.$refs.modalUserStats.show()
      })
    },
    generateRandomColor() {
      const x = Math.floor(Math.random() * 256)
      const y = Math.floor(Math.random() * 256)
      const z = Math.floor(Math.random() * 256)
      return `rgb(${x}, ${y}, ${z})`
    },
    onSortByStatus(sort) {
      this.sortByStatus = sort
      this.search()
    },
    onSortByPrice(sort) {
      this.sortByPrice = sort
      this.search()
    },
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      const params = this.loadParams()
      this.path = `${lang}/console/${console}/tournament/${slug}/signings`
      this.$axios.get(this.path, { params: params }).then((response) => {
        const data = response.data
        this.players = data.data.data
        this.rows = data.data.total
        this.perPage = data.data.per_page
        this.currentPage = data.data.current_page
        this.inside = data.inside
        this.gameHere = data.game_here
        this.tournamentIsPremium = data.is_premium
        this.iAmDt = data.i_am_dt
      })
    },
    loadParams() {
      return {
        sortbyprice: this.sortByPrice,
        sortbystatus: this.sortByStatus,
        nick: this.nick,
        position: this.position
      }
    },
    search() {
      const query = this.loadParams()
      const slug = this.$route.params.tournamentSlug
      this.$router.push({ name: 'TournamentSignings', params: { lang: this.lang, console: this.console, tournamentSlug: slug}, query })
      this.paginate()
    },
    paginate(page = false) {
      const params = this.loadParams()
      params.page = page ? page : this.currentPage
      this.$axios.get(this.path, { params }).then((response) => {
        const data = response.data.data
        this.players = data.data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
      })
    }
  }
}
</script>
